body {
}
div {
  margin: 0px 0 5px 0;
}
.depthSelect div,
.seriesSelect div {
  margin: 0 !important;
}
#crumb {
  margin: 15px 0 0 0;
}
#calcYear {
  margin-top: 15px;
}
#dashi {
  margin: 8px 0px;
}

#app,
#wrap {
  margin: 0 0 0 0 !important;
}

#common_crop_config {
  position: absolute;
  bottom: 15px;
}

#pageController {
  /* position: fixed;
  top: 845px;
  left: 285px;
  right: 1395px;
  bottom: 708px;*/
  margin: 0px 0 -20px 0;
  width: 100%;
}
.bg-light {
  background-color: #e8f5e9 !important;
}
.bg-light > span,
.bg-light > a {
  /*color: #FFFFFF !important;*/
}

#pageController > .row > .col-md-1 {
  max-width: 51% !important;
}

@media screen and (max-width: 575px) {
  /*600px以上で適用する内容*/
  #pc {
    height: 60px !important;
  }
  #culti {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  /*600px以上で適用する内容*/
  #pc {
    /*height: 173px !important;*/
  }
  #datainfo {
    margin-top: 15px;
  }
  #crumb {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  /*600px以上で適用する内容*/
  h5 {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 1096px) {
  #culti {
    top: 44px !important;
  }
}

@media screen and (max-width: 767px) {
  .init {
    margin: 15px 0 0 0 !important;
  }

  .cutiConfig {
    margin-left: 15px;
  }
  #common_crop_config {
    position: absolute;
    bottom: 25px;
    right: 35px;
  }
}
@media screen and (max-width: 575px) {
}

.init {
  margin-left: 15px;
}

.info:hover {
  color: #cdcdcd;
}
.info {
  padding-top: 12px;
}
#help {
  position: absolute;
  bottom: 0;
  right: 0;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff;
}
.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100000;
  background-color: #999;
  opacity: 0.8;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  color: #fff;
  display: none;
}
.scroll-top:hover {
  cursor: pointer;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
  background: #e8f5e9;
  left: 0;
}

.scroll-down {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100000;
  background-color: #999;
  opacity: 0.8;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  color: #fff;
  display: block;
}

.displayInlinBlock {
  display: inline-block;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.icon-page-controller {
  margin-right: 10px;
  margin-top: -2px;
}
.buttonHref {
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: #007bff;
}

.buttonHref:hover,
.buttonHref:focus {
  outline: 0px auto -webkit-focus-ring-color;
}

#map,
.leaflet-pane {
  height: 35em;
  width: 100%;
}
.collapse,
#map {
  margin: 0 0 0 0;
}

.btn-dark {
  color: #fff;
  background-color: #2f4f4f;
  border-color: #2f4f4f;
}
#mynetwork {
  width: 600px;
  height: 400px;
  border: 1px solid lightgray;
}

.vis-timeline * {
  margin: 0px !important;
}

.fa-expand {
  font-size: 15px;
}

.menu * {
  margin: 0px;
  font-size: 30px;
}

#moveRight,
#moveLeft {
  position: relative;
  left: 50%;
  right: 50%;
}
#amedasIcon {
  font-size: 50px;
  margin: 0 0 0 0;
}

#mapIcon {
  font-size: 20px;
  margin: 0 10px 0 0;
}

#irrigateIcon {
  width: 40px;
  height: 40px;
}

.disabled-link {
  pointer-events: none;
}

#cropico,
#fertico,
#cultiico {
  position: relative;
  top: 9px;
  height: 40px;
  z-index: -9999;
}
#fa_arrow_down {
  font-size: 10px;
  display: block;
  position: relative;
  left: 20%;
}

.cropLabel {
  display: inline-block;
  top: 15px;
  position: relative;
}
#resultico {
  margin: 5px;
  position: relative;
  top: -3px;
}
.tips {
  font-size: 1.5em;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

#et0Value:-moz-ui-invalid {
  box-shadow: none;
}

.breadcrumb {
  background-color: #fff !important;
  margin-bottom: 0 !important;
  /*margin: 0.75rem 0 !important;
  padding: 0.75rem 0 !important;*/
}
