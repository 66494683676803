#timeline {
  width: auto;
  border-radius: 5px;
}
/*.menu {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  z-index: 9999;
}*/
.vis-item {
  /*border-color: #F991A3;*/
  /*background-color: #ff69b4;*/
}
.vis-odd {
  background: #eceff1;
}
.vis-even {
  background: #cfd8dc;
}
.vis-range {
  border-radius: 5px;
  color: #000;
  border: 0px;
}
.box-type-class {
  color: #000;
}
.vis-time-axis {
  background-color: #cfd8dc;
}
.vis-item.vis-selected {
  border-color: green;
  background-color: lightgreen;
}

#loading {
  position: absolute;
  right: 0%;
  left: 30%;
  top: 0%;
  z-index: 9999;
  width: 30em;
  height: auto;
}
